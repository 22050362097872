import { Component } from '@angular/core';
import * as moment from 'moment';

import { Company } from '../../models/company';
import { UserService } from '../../service';
import { FeatureService } from '../../service/feature.service';

const DAYS_TRIAL = 14;
@Component({
  selector: 'app-payment-prompt',
  templateUrl: './payment-prompt.component.html',
  styleUrls: ['./payment-prompt.component.scss']
})
export class PaymentPromptComponent {
  public company: Company;
  public onTrial: boolean;
  public trialDaysRemaining: number;
  public hasChurned: boolean;
  public isChurning: boolean;
  public paymentFailed: boolean;
  public displayDiscount = false;
  public discountEndDate: string;
  public legacyUx = this.featureService.has('legacy-ux');

  constructor(
    private userService: UserService,
    private featureService: FeatureService
  ) {
    if (this.userService.user) {
      this.onTrial = this.userService.user.Company.trial;
      this.company = this.userService.user.Company;
      this.trialDaysRemaining = this.daysRemaining();
      this.displayDiscount = this.isDiscountPeriod();
      this.hasChurned = !!this.company.churnedDate;
      this.isChurning = !!this.company.cancellationRequested || !!this.company.nickelledCancelled;
      this.paymentFailed = this.company.paymentFailedCount > 0;
      this.onTrial = this.userService.user.Company.trial;
    }
  }

  daysRemaining() {
    const trialEnd = moment(this.company.trialEndDate);
    const now = moment();

    return trialEnd.diff(now, 'days');
  }

  isDiscountPeriod() {
    const created = moment(this.company.createdAt);
    const now = moment();
    const signupDaysAgo = now.diff(created, 'days');

    if (this.company.trial && signupDaysAgo < DAYS_TRIAL) {
      this.discountEndDate = moment(this.company.createdAt).add('days', 14).format('MMM Do');

      return true;
    } else {
      return false;
    }
  }

}
