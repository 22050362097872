<div *ngIf="legacyUx | async; else newWrapper">
  <div class="flex flex-col min-h-screen" [style.background-color]="legacyStyles ? '#fff' : '#f1f7f9'">
    <div class="legacy">
      <div class="z-10 flex sans-serif flex-column w-100 h-100">
        <app-flash></app-flash>
        <header class="w-100 bg-washed-blue cf" *ngIf="activeHeader">
          <div class="fl h-100 ph2 pt2">
            <img class="h2" src="assets/images/ni-symbol-logo.svg" alt="Nickelled logo"
              title="Thanks for using Nickelled :)" />
          </div>
          <ul class="list pa0 ma0 ml3 fl">
            <li class="dib ph2" *ngIf="onboardingEnabled">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" routerLink="/start"
                [ngClass]="{'bw2 bb bb--orange' : currentSection === 'start'}">
                Get started<span class="orange ml1"> ({{ onboardingStatus }})</span>
              </a>
            </li>
            <li class="dib ph2">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" routerLink="/guides"
                [ngClass]="{'bw2 bb bb--orange' : currentSection === 'guides'}">
                Guides
              </a>
            </li>
            <li class="dib ph2" *ngIf="legacyUxWithFlows | async">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" target="flows" href="https://app.nickelled.com">
                Flows
              </a>
            </li>
            <li class="dib ph2">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" routerLink="/launchers"
                [ngClass]="{'bw2 bb bb--orange' : currentSection === 'launchers'}">
                Launchers
              </a>
            </li>
            <li class="dib ph2">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" routerLink="/academies"
                [ngClass]="{'bw2 bb bb--orange' : currentSection === 'academies'}">
                Academies
              </a>
            </li>
            <li class="dib ph2">
              <a class="no-underline dib pt3 pb1 ph0 navy b f6" routerLink="/settings"
                [ngClass]="{'bw2 bb bb--orange' : currentSection === 'settings'}">Settings</a>
            </li>
            <li class="dib">
              <a class="no-underline dib pv3 ph2 navy b f6" href="http://help.nickelled.com" target="_blank">Help</a>
            </li>
          </ul>

          <app-payment-prompt></app-payment-prompt>

          <div class="fr pa2" *ngIf="legacyStyles">
            <a href="https://make.nickelled.com"
              class="no-underline bw0 bg-blue white br2 pv2 ph3 pointer f6 dib v-top">Make guide</a>
          </div>
        </header>
      </div>
    </div>
    <div [class.legacy]="legacyStyles">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #newWrapper>
  <div class="min-h-screen bg-ni-blue-400">
    <app-flash></app-flash>
    <router-outlet></router-outlet>
  </div>
</ng-template>