import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventTrackingService } from './event-tracking.service';
import { HttpService } from './http.service';
import { User } from './user';
import { UserService } from './user.service';

const ONBOARDING_FLOW = [
  {
    "title": "Watch a short video introduction",
    "actions": [
      {
        "type": "video",
        "image": "/assets/images/start/intro-video.png",
        "description": "This video contains important information on how Nickelled is different from anything else you’ve tried - we highly recommend taking three minutes to watch it.",
        "completionEvent": "getting_started_video_opened",
        "buttonLabel": "Play the video now"
      }
    ]
  },
  {
    "title": "Make your first guide",
    "actions": [
      {
        "type": "link",
        "image": "/assets/images/start/guide.png",
        "description": "Use our intuitive editor to make your first guided tour. Show users around your app with no coding or plugins required.",
        "href": "https://make.nickelled.com/",
        "completionEvent": "guide_ready",
        "buttonLabel": "Make your first guide"
      }
    ]
  },
  {
    "title": "Share your help and training content",
    "actions": [
      {
        "type": "link",
        "image": "/assets/images/launchers/mission-dock.svg",
        "href": "/launchers",
        "description": "Set up a Mission Dock to provide your users with help content inside your app.",
        "completionEvent": "mission_dock_created",
        "buttonLabel": "Set up a Mission Dock"
      },
      {
        "type": "or"
      },
      {
        "type": "link",
        "image": "/assets/images/start/academy.svg",
        "href": "/academies",
        "description": "Add a guide to your Academy to share your help content on a dedicated help site.",
        "completionEvent": "academy_content_created",
        "buttonLabel": "Add a guide to your Academy"
      }
    ]
  }
];

const ONBOARDING_DISMISSED_EVENT = "onboarding_dismissed";

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  public steps: any[] = ONBOARDING_FLOW;
  public onboardingEnabled = new BehaviorSubject<boolean>(false);
  public stepCompletionStatus = new BehaviorSubject<boolean[]>([false, false, false]);
  private eventsCompleted = [];

  constructor(
    private userService: UserService,
    private httpService: HttpService,
    private eventTrackingService: EventTrackingService
  ) {
    this.userService.user$.subscribe(() => this.updateOnboardingProgress());

    this.eventTrackingService.events.subscribe(([event, data]: [string, any]) => {
      const onboardingEventName = this.transformToOnboardingEvent(event, data);

      if (onboardingEventName) {
        this.addEventsToCompletedArray([onboardingEventName]);
        this.sendEventToNickelled(onboardingEventName);
        this.updateOnboardingProgress();
      }
    });
  }

  private updateOnboardingProgress() {
    if (this.userService.user) {
      this.addEventsToCompletedArray(this.userService.user.completedOnboardingSteps || []);

      const stepCompletionStatus = this.steps.map((step) => this.isStepCompleted(step, this.eventsCompleted));
      this.stepCompletionStatus.next(stepCompletionStatus);

      const onboardingEnabled = this.isOnboardingEnabled(this.userService.user, this.eventsCompleted);
      this.onboardingEnabled.next(onboardingEnabled);
    }
  }

  public isOnboardingEnabled(user: User, eventsCompleted: string[]) {
    return this.userService.user.onboardingEnabled && !eventsCompleted.includes(ONBOARDING_DISMISSED_EVENT);
  }

  private isStepCompleted(step: any, eventsCompleted: string[]) {
    return step.actions.some((action) => action.completionEvent && eventsCompleted.includes(action.completionEvent));
  }

  private transformToOnboardingEvent(event: string, data?: any) {
    if (event === "launcher_created" && data.launcher_type === "mission-dock") {
      return "mission_dock_created";
    } else if (event === "onboarding_interaction" && data.type === "video-modal-opened") {
      return "getting_started_video_opened";
    } else if (event === "onboarding_dismissed") {
      return event;
    } else if (event === "academy_content_created") {
      return event;
    } else if (event === "guide_ready") {
      return event;
    }

    return null;
  }

  private addEventsToCompletedArray(events: string[]) {
    events.forEach((event) => {
      if (this.eventsCompleted.indexOf(event) === -1) {
        this.eventsCompleted.push(event);
      }
    });
  }

  private sendEventToNickelled(event: string) {
    if (this.userService.user.id && environment.launchers.appId && event !== null) {
      const url = `${environment.apiHost}/sites/${environment.launchers.appId}/event`;
      this.httpService.post(url, {
        event: event,
        userId: this.userService.user.id
      }, {
        withCredentials: true
      }).subscribe(() => { }, (err) => { console.error("Failed to sent event to Nickelled", err); });
    }
  }
}
