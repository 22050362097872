import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-start-button',
  templateUrl: './start-button.component.html',
  styleUrls: ['./start-button.component.scss']
})
export class StartButtonComponent implements OnInit {

  @Input() icon: IconDefinition = null;
  @Input() color = "orange5";
  @Input() variant = "3d";

  get buttonCssClass() {
    const classes = ["button"];
    classes.push(`color_${this.color}`);
    this.variant.split(' ').forEach(x => classes.push(`variant_${x}`));
    return classes.join(' ');
  }

  constructor() { }

  ngOnInit() {
  }

}
