import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNameFormatPipe } from './user-name-format.pipe';
import { GuideLanguagePipe } from './guide-language.pipe';
import { ActiveFilterPipe } from './active-filter.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [
    UserNameFormatPipe,
    GuideLanguagePipe,
    ActiveFilterPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UserNameFormatPipe,
    GuideLanguagePipe,
    ActiveFilterPipe,
    SafeUrlPipe
  ]
})
export class PipeModule { }
