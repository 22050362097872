import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from '../models';

declare var analytics: any;
declare var Intercom: any;
declare var NickelledLaunchers: any;

declare global {
  interface Window { dataLayer: any; }
}

@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {

  constructor() { }

  events = new Subject<[string, any]>();

  init(): void {
    if (!environment.offlineMode) {
      analytics.load(`${environment.segmentAPIKey}`);
      Intercom('shutdown');
      Intercom('boot', {
        app_id: environment.intercomAppId,
        widget: { activator: '#IntercomDefaultWidget' },
      });
    }

    this.loadLaunchersJs();
  }

  unixDate(date) {
    if (date) {
      return Date.parse(date) / 1000;
    } else {
      return false;
    }
  }

  identify(user: User): void {
    const segmentUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.secondName,
      email: user.email,
      createdAt: user.createdAt
    };

    const company = user.Company;

    const intercomUser = {
      user_id: user.id,
      name: `${user.firstName} ${user.secondName}`,
      email: user.email,
      phone: user.phone,
      created_at: this.unixDate(user.createdAt),
      weekly_update_email: user.weeklyEmail,
      guides_count: user.totalGuidesCount,
      company: {
        company_id: company.id,
        name: company.name,
        paid: !!company.paid,
        trial: company.trial,
        trial_end_at: this.unixDate(company.trialEndDate),
        plan: company.packageId,
        package_id: company.packageId,
        plan_name: company.packageName,
        card_expiry_month: company.ccExpiryMonth || 0,
        card_expiry_year: company.ccExpiryYear || 0,
        card_last_4: company.ccLast4,
        // companyGuideCount: #{companyGuideCount}, NOT PROVIDED BY AUTH
        theme_created: !!company.styling,
        signup_reason: company.signupReason,
        created_at: this.unixDate(company.createdAt)
      }
    };

    const nickelledUser = {
      appId: environment.launchers.appId,
      apiUrl: environment.launchers.apiUrl,
      trackingUrl: environment.launchers.trackingUrl,
      userId: user.id,
      email: user.email,
      companyId: company.Id,
      guideCount: user.totalGuidesCount,
      createdAt: user.createdAt,
      paid: !!company.paid
    };

    if (!environment.offlineMode) {
      analytics.identify(user.id, segmentUser);
      Intercom('update', intercomUser);
    }

    NickelledLaunchers.setUser(nickelledUser);
  }

  action(event: string, data?): void {
    if (!environment.offlineMode) {
      analytics.track(event, data);
      Intercom('trackEvent', event, data);
      const dataLayer = window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event,
        params: data
      });
    }

    this.events.next([event, data]);
  }

  page(name: string, params?: {}): void {
    if (!environment.offlineMode) {
      analytics.page(name, params);
      Intercom('trackEvent', name, params);
      const dataLayer = window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'pageView',
        params
      });
    }
  }

  formChanges(event: string, ngForm): void {
    if (!environment.offlineMode) {
      const changedFields = {};

      Object.keys(ngForm.controls)
        .forEach(key => {
          const currentControl = ngForm.controls[key];

          if (currentControl.dirty) {
            let fieldValue = true; // Mark fieldValue as true when not string/number

            if (typeof currentControl.value === 'string' ||
              typeof currentControl.value === 'number' ||
              typeof currentControl.value === 'boolean') {

              fieldValue = currentControl.value;
            }

            changedFields[key] = fieldValue;
          }

          currentControl.markAsPristine();
        }
        );

      this.action(event, changedFields);
    }
  }

  private loadLaunchersJs() {
    (<any>window).NickelledLaunchers = (<any>window).NickelledLaunchers || { setUser: function (u) { this.userData = u } };
    const s = document.createElement("script");
    s.async = true;
    s.src = environment.launchers.js;
    const f = document.getElementsByTagName("script")[0];
    f.parentNode.insertBefore(s, f);
  }
}
