import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent {
  makeUrl: string;
  errors = [];

  constructor(
    private router: Router
  ) { }

  submitForm( $event ) {
    $event.preventDefault();
    this.errors = [];

    if (!this.makeUrl || this.makeUrl.length === 0 ) {
      this.errors.push('Please enter a URL');
      return false;
    } else {

      if ( this.makeUrl.indexOf('.') === -1 ) {
        this.errors.push('Please enter a valid URL');
        return false;
      } else {
        const hasHttpPrefix = /^(http|https):\/\/[^ "]+$/.test( this.makeUrl );
        if (!hasHttpPrefix ) {
          this.makeUrl = 'http://' + this.makeUrl;
        }
      }
    }

    this.router.navigate([`guides`]);

    window.open( `${environment.makeHost}/device/desktop/create?address=${this.makeUrl}`, '_blank');
  }
}
