import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlashService {

  private flashMessageSource = new Subject<string>();

  flashMessage$ = this.flashMessageSource.asObservable();

  set( message: string ) {
    this.flashMessageSource.next( message );
  }
}
