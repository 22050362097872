<ng-container *ngIf="(legacyUx | async); else newUx">
  <div class="dib fl h-100 ml3 payment-prompt-separator" *ngIf="onTrial && trialDaysRemaining >= 0">
    <a class="payment-prompt dib pt3 pb1 mh3 navy b f6 no-underline bw1" href="/settings/subscribe">
      {{ trialDaysRemaining }} days left on trial, change subscription settings
    </a>
    <!-- <a class="dib white mb1 mr1 pa1 br2 f6 no-underline lh-solid bg-orange" *ngIf="displayDiscount" routerLink="/subscribe">
    +10% off if you sign up before {{ discountEndDate }}!
  </a> -->
  </div>
  <!-- <div class="dib fl h-100 ml3 payment-prompt-separator" *ngIf="onTrial && trialDaysRemaining < 0">
  <a class="payment-prompt dib pt3 pb1 mh3 navy b f6 no-underline bw1" routerLink="/subscribe">Your free trial is now over, upgrade now</a>
</div> -->
  <div class="dib fl h-100 ml3 payment-prompt-separator" *ngIf="paymentFailed">
    <a class="payment-prompt dib pt3 pb1 mh3 navy b f6 no-underline bw1" href="/settings/subscription">We've been unable
      to take payment for your account. Please update your card.</a>
  </div>

  <div class="dib fl h-100 ml3 payment-prompt-separator" *ngIf="isChurning && !hasChurned">
    <a class="payment-prompt dib pt3 pb1 mh3 navy b f6 no-underline bw1" href="/settings/subscribe">Your account is due
      to be cancelled. Changed your mind? Click here to keep access.</a>
  </div>
</ng-container>

<ng-template #newUx>
  <div *ngIf="onTrial && trialDaysRemaining >= 0">
    <a class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-ni2-orange-500 text-white"
      href="/settings/subscribe">
      {{ trialDaysRemaining }} days left on trial, change subscription settings&hellip;
    </a>
  </div>

  <div *ngIf="paymentFailed">
    <a class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-ni2-orange-500 text-white"
      href="/settings/subscription">We've been unable to take payment for your account. Please update your card.</a>
  </div>

  <div *ngIf="isChurning && !hasChurned">
    <a class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-ni2-orange-500 text-white"
      href="/settings/subscribe">Your account is due to be cancelled. Changed your mind? Click here to keep access.</a>
  </div>
</ng-template>