import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './user/auth.guard';
import { TrialOrCustomerGuardService } from './user/trial-or-customer-guard.service';
import { SharedModule } from './shared/shared.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InvitationComponent } from './user/invitation/invitation.component';

import {
  HttpService,
  UserService,
  TokenInterceptor
} from './shared/service';

import { ConfirmationDialogService } from './shared/components/confirmation-dialog/confirmation-dialog.service';
import { ArticleModalService } from './academies/academy-manage/page-manage/article-modal/article-modal.service';
import { WizardComponent } from './wizard/wizard.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { StartComponent } from './start/start.component';
import { errorHandlerFactory } from './error-handler';

@NgModule({
  declarations: [
    AppComponent,
    InvitationComponent,
    WizardComponent,
    StyleGuideComponent,
    StartComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    SortablejsModule.forRoot({ animation: 150 }),
    FontAwesomeModule
  ],
  providers: [
    HttpService,
    UserService,
    ConfirmationDialogService,
    ArticleModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    },
    AuthGuard,
    TrialOrCustomerGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
