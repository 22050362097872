import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';

import { AppStorage } from './storage';
import { Guide } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuideService {
  private guidesUrl = environment.apiHost + '/company/guides.json';

  constructor(
    private httpService: HttpService,
    private userService: UserService
  ) { }

  create(guide: Guide) {
    const url = environment.apiHost + `/guides`;

    return this.httpService.post(url, guide, { withCredentials: true });
  }

  update(guide: Guide) {
    const url = `${environment.apiHost}/users/guides/${guide.id}.json`;

    return this.httpService.post(url, guide, { withCredentials: true });
  }

  getGuide(guideId: number) {
    const url = `${environment.apiHost}/users/guides/${guideId}.json`;

    return this.httpService.get(url, { withCredentials: true });
  }

  getGuideList(force = false): Observable<any> {
    const timeStamp = +new Date();
    const uniqueGuidesUrl = this.guidesUrl + '?ts=' + timeStamp;

    const params = {
      companyId: this.userService.user.CompanyId
    };

    return AppStorage.cache(
      `get-guides-${this.userService.user.CompanyId}`,
      this.httpService.get(uniqueGuidesUrl, { params, withCredentials: true }),
      force
    );
  }
}
