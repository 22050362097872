import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  private subject = new Subject<any>();

  constructor() { }

  confirmThis(message: string, okText: string, cancelText: string, siFn: () => void, noFn: () => void) {
    this.setConfirmation(message, okText, cancelText, siFn, noFn);
  }

  setConfirmation(message: string, okText: string, cancelText: string, siFn: () => void, noFn: () => void) {
    const that = this;
    this.subject.next({
      type: 'confirm',
      text: {
        message,
        okText,
        cancelText
      },
      siFn() {
          that.subject.next(); // this will close the modal
          siFn();
        },
      noFn() {
          that.subject.next();
          noFn();
      }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
