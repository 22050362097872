<div class="vh-100 flex justify-center">
  <section class="measure flex-auto ma1 mt5-ns">
    <header class="">
      <img src="https://cdn.nickelled.com/colours-and-shapes/0.5.2/images/logo.svg" alt="Nickelled Logo" width="120px" class="mb3"/>
      <h1 class="f3 navy">Join Nickelled&hellip;</h1>
    </header>
  <div class="bg-washed-blue br3 br--all pa3">

    <form #signupForm="ngForm" (ngSubmit)="signup(signupForm)">
      <div class="mb3">
        <label class="db b mb1 f6">What's your name?</label>
        <div class="flex na1">
          <input class="flex-auto pa2 ba b--lightest-blue br2 mv1 mh1" ngModel name="firstName" type="text" placeholder="First name" autofocus="true" required minlength="2" />
          <input class="flex-auto pa2 ba b--lightest-blue br2 mv1 mh1" ngModel name="secondName" type="text" placeholder="Last name" required minlength="2"/>
        </div>
      </div>

      <div class="mb3">
        <label class="db b mb1 f6">What's your email address?</label>
        <input class="w-100 pa2 ba b--lightest-blue br2" ngModel name="email" type="email" placeholder="name@company.com" />
        <span class="db f7 mt1 lh-copy">Must match the invited email address.</span>
      </div>

      <div class="mb3">
        <label class="db b mb1 f6">Choose a password</label>
        <input class="w-100 pa2 ba b--lightest-blue br2" ngModel name="password" type="password" placeholder="Password" />
        <span class="db f7 mt1 lh-copy">Must be 5 characters or longer.</span>
      </div>

      <div class="mb3">
        <input type="submit" class="input-reset button-reset bw0 btn-primary br2 pv2 ph3 pointer f6" value="Signup" />
      </div>

      <aside class="bg-white br2 ba b--lightest-blue pa2 f6 b--dashed">
        <span class="b">Already have an account?</span> <a href="/login" class="orange">Login</a>
      </aside>

    </form>
  </div>
  </section>
</div>
<app-loading-indicator *ngIf="loading"></app-loading-indicator>