<div class="modal">
  <div class="body">
    <div class="header">
      <h3>{{ title }}</h3>
      <button class="close" (click)="handleClose()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
