import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private userService: UserService) {}

  has(name: string) {
    return this.userService.user$.pipe(
      map(
        (user) =>
          user?.Company?.features?.some((feature) => feature.name === name) ||
          false
      )
    );
  }

  hasNot(name: string) {
    return this.has(name).pipe(map((hasFeature) => !hasFeature));
  }
}
