import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpService, UserService, FlashService, EventTrackingService } from '../../shared/service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  private id: number;
  public loading: boolean;
  private headers = new HttpHeaders();
  private options;

  constructor(
    private userService: UserService,
    private eventTrackingService: EventTrackingService,
    private flashService: FlashService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private titleService: Title
  ) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
    this.titleService.setTitle('Invitation | Nickelled Dashboard');
  }

  ngOnInit() {
    this.userService.getUser().subscribe(
      user => {
        if (user.id) {
          this.router.navigateByUrl(`/`);
        }
      }
    );
  }

  signup(signupForm) {
    const user = signupForm.value;
    this.loading = true;
    this.httpService.post(`/invitations/${this.id}`, user, this.options).subscribe(
      newUser => {
        this.flashService.set('Welcome to Nickelled. You\'re now logged in.');
        this.userService.setUser(newUser);
        this.router.navigateByUrl(`/`);
        this.loading = false;
      }, err => {
        this.loading = false;
        this.flashService.set('Signup failed. Please make sure you use the same email.');
      }
    );
  }
}
