<app-modal title="Introduction" (close)="setVideoModalOpen(false)" *ngIf="videoModalOpen">
  <iframe type="text/html" width="960" height="585"
    src="https://www.youtube.com/embed/Lq5-3DPK9Os?autoplay=1&amp;modestbranding=1" frameborder="0"
    allow="autoplay; fullscreen"></iframe>
</app-modal>

<div *ngIf="onboardingEnabled" class="center pv4" style="max-width: 1200px;">
  <div class="flex">
    <div class="ph4 w-65">
      <h1 class="f3">Getting started with Nickelled</h1>

      <div class="f4 lh-copy">Hi{{ userFirstName ? ' ' + userFirstName : '' }}, welcome to Nickelled! Here are some steps that will help you
        get started.</div>

      <div class="card" *ngFor="let step of steps; index as i" [class.expanded]="isExpanded(i)"
        [class.completed]="stepCompletionStatus[i]">
        <div class="label">
          <div>{{ i + 1 }}</div>
        </div>
        <div class="body">
          <div class="header" (click)="toggleExpanded(i)">
            <h3>{{ step.title }}</h3>
            <span *ngIf="stepCompletionStatus[i]" class="mr2 icon">
              <fa-icon [icon]="faCheck"></fa-icon>
            </span>
            <button *ngIf="nextStep !== i" class="expander">
              <fa-icon [icon]="expandedStep === i ? faAngleUp : faAngleDown"></fa-icon>
            </button>
          </div>
          <ul class="ma0 pa0 content">
            <ng-template ngFor let-action [ngForOf]="step.actions" let-j="index">
              <li *ngIf="action.type !== 'or'" class="overflow-hidden">
                <div *ngIf="action.image" class="preview fl mr4"
                  [style.background-image]="'url(' + action.image + ')'" (click)="launchAction(action)">
                  <fa-icon *ngIf="action.type === 'video'" [icon]="faPlay"></fa-icon>
                </div>
                <div class="pv2">
                  <p *ngIf="action.description" class="lh-copy">{{ action.description }}</p>
                  <app-start-button *ngIf="action.buttonLabel" (click)="launchAction(action)">
                    {{ action.buttonLabel }}</app-start-button>
                </div>
              </li>
              <li *ngIf="action.type === 'or'" class="or">
                <div>or</div>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>

      <div *ngIf="allStepsComplete" class="f4 mv4 pa4 alert">
        <div class="mb3">
          <strong>Well done!</strong> You've completed the getting started checklist.
        </div>
        <app-start-button color="navy5" (click)="dismissPage()">Hide this page in the future</app-start-button>
      </div>
    </div>

    <div class="ph4 pv4 w-35">
      <div class="bg-washed-blue pa4 br3">
        <div class="f5 lh-copy">Want to chat? Get in touch with a customer success expert:</div>
        <app-start-button class="db mt4" variant="3d block" [icon]="faPhone" color="navy5" (click)="navigateToDemoPage()">
          Schedule a call</app-start-button>
        <app-start-button class="db mt2" variant="3d block" [icon]="faCommentDots" color="navy5"
          (click)="launchIntercomChat()">Start a live chat</app-start-button>
      </div>
    </div>
  </div>
</div>
