import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCommentDots,
  faPhone,
  faPlay,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { of, Subject } from 'rxjs';
import { concatMap, map, take, takeUntil } from 'rxjs/operators';
import { EventTrackingService, UserService } from '../shared/service';
import { FeatureService } from '../shared/service/feature.service';
import { OnboardingService } from '../shared/service/onboarding.service';

declare var Intercom: any;

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit, OnDestroy {
  nextStep = 0;
  expandedStep = -1;

  videoModalOpen = false;
  userFirstName = null;
  steps: any[] = [];
  stepCompletionStatus: boolean[] = [];
  allStepsComplete = false;
  onboardingEnabled = false;

  faPhone = faPhone;
  faCommentDots = faCommentDots;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faPlay = faPlay;
  faCheck = faCheck;
  faTimes = faTimes;

  destroy = new Subject();

  constructor(
    private eventTrackingService: EventTrackingService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private router: Router,
    private featureService: FeatureService
  ) {
    this.onboardingService.stepCompletionStatus.subscribe(
      (stepCompletionStatus) => {
        this.stepCompletionStatus = stepCompletionStatus;
        const firstIncompleteIndex = this.stepCompletionStatus.findIndex(
          (x) => !x
        );
        this.nextStep = firstIncompleteIndex !== -1 ? firstIncompleteIndex : -1;
        this.allStepsComplete = firstIncompleteIndex === -1;
        this.expandedStep = -1;
      }
    );
  }

  ngOnInit() {
    this.steps = this.onboardingService.steps;
    this.userFirstName = this.userService.user.firstName;

    this.userService.user$.subscribe((user) => {
      if (user) {
        this.userFirstName = user.firstName;
      }
    });

    this.featureService
      .has('legacy-ux')
      .pipe(
        concatMap((legacyUx) => {
          if (legacyUx) {
            return this.onboardingService.onboardingEnabled.pipe(
              map((onboardingEnabled) =>
                onboardingEnabled ? null : ['/', 'guides']
              )
            );
          }

          return of(['/', 'flows']);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((redirectTo) => {
        this.onboardingEnabled = redirectTo === null;
        if (redirectTo !== null) {
          this.router.navigate(redirectTo);
        }
      });
  }

  isExpanded(stepIndex: number) {
    return this.nextStep === stepIndex || this.expandedStep === stepIndex;
  }

  toggleExpanded(stepIndex: number, event: Event) {
    if (this.isExpanded(stepIndex)) {
      this.expandedStep = -1;
    } else {
      this.expandedStep = stepIndex;
    }
  }

  setVideoModalOpen(open: boolean) {
    this.videoModalOpen = open;
    const interactionType = open ? 'video-modal-opened' : 'video-modal-closed';
    this.eventTrackingService.action('onboarding_interaction', {
      type: interactionType,
    });
  }

  launchIntercomChat() {
    this.eventTrackingService.action('onboarding_interaction', {
      type: 'live-chat',
    });
    Intercom('show');
  }

  navigateToDemoPage() {
    this.eventTrackingService.action('onboarding_interaction', {
      type: 'schedule-call',
    });
    window.location = 'https://www.nickelled.com/request-demo/' as any;
  }

  launchAction(action: any) {
    if (action.type === 'link') {
      this.eventTrackingService.action('onboarding_interaction', {
        type: 'launch-action',
      });
      window.location = action.href;
    } else if (action.type === 'video') {
      this.setVideoModalOpen(true);
    }
  }

  dismissPage() {
    this.eventTrackingService.action('onboarding_dismissed');
    this.router.navigate(['/', 'guides']);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
