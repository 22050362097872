import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  faTimes = faTimes;

  @Input() title = "";
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  handleClose() {
    this.close.emit();
  }

}
