import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { User } from './user';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;
  public user$ = new BehaviorSubject<User>(null);

  constructor(
    private httpService: HttpService
  ) { }

  logIn(email, password): Observable<User> {
    const authenticateUrl = `${environment.apiHost}/authenticate`;

    return this.httpService.post(authenticateUrl, { email, password }, { withCredentials: true }).pipe(
      map(res => {
        this.httpService.authFail = false;
        return res;
      }, err => {
        this.httpService.authFail = true;
      }),
    );
  }

  getUser(): Observable<User> {
    const authenticateUrl = `${environment.apiHost}/authenticate`;
    return this.httpService.get(authenticateUrl, { withCredentials: true }).pipe(
      map(res => {
        this.user = res;

        this.httpService.authFail = false;
        return res;
      }, err => {
        return err;
      })
    );
  }

  setUser(user: User) {
    this.user = user;
    this.user$.next(user);
  }

  public getByUseID(userId: number): Observable<User> {
    const getUserUrl = `${environment.apiHost}/users/${userId}.json`;
    return this.httpService.get(getUserUrl, { withCredentials: true }).pipe(
      map(res => {
        return res;
      })
    );
  }

  public getOrgCompany(orgId): Observable<any> {
    const url = `${environment.apiHost}/organisations/${orgId}/companies`;

    return this.httpService.get(url, { withCredentials: true }).pipe(
      map(res => {
        return res;
      })
    );
  }

  register(accountDetails: any): Observable<any> {
    const registerUrl = `${environment.apiHost}/register`;
    return this.httpService.post(registerUrl, accountDetails, { withCredentials: true }).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError((errors: Response) => {
        return of(errors);
      })
    );
  }

  public sendResetLink(credentials: any): Observable<any> {
    const resetUrl = environment.apiHost + `/password_reset`;
    return this.httpService.post(resetUrl, credentials, { withCredentials: true }).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError((errors: Response) => {
        return of(errors);
      })
    );
  }

  public updatePassword(newCredentials: any, token: string): Observable<any> {
    const resetUrl = environment.apiHost + `/password_reset/${token}`;
    return this.httpService.post(resetUrl, newCredentials, { withCredentials: true }).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError((errors: Response) => {
        return of(errors);
      })
    );
  }

  public cancelEmailSubscription(subscription: string, email: string, token: string): Observable<boolean> {
    const encodedSubscription = encodeURIComponent(subscription);
    const unsubscribeUrl = environment.apiHost + `/emails/${encodedSubscription}/unsubscribe`;

    let params = new HttpParams()
      .set('email', email)
      .set('token', token);

    return this.httpService.get(unsubscribeUrl, {
      observe: 'response',
      responseType: 'text',
      params
    }).pipe(
      map((res: Response) => {
        return res.status === 200;
      }),
      catchError((errors: Response) => {
        return of(false);
      })
    );
  }

}
