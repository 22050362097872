import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArticleModalService {
  private subject = new Subject<any>();

  constructor() { }

  confirmThis(articleData: any, siFn: () => void, noFn: () => void) {
    this.setArticleData(articleData, siFn, noFn);
  }

  setArticleData(articleData: any, siFn: () => void, noFn: () => void) {
    const that = this;
    this.subject.next({
      type: 'confirm',
      articleData,
      siFn() {
        that.subject.next(); // this will close the modal
        siFn();
      },
      noFn() {
        that.subject.next();
        noFn();
      }
    });
  }

  getArticleData(): Observable<any> {
    return this.subject.asObservable();
  }
}
