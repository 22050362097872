import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const fullUrl = req.headers.get('fullurl') || req.url.startsWith('http');

    const newRequest = fullUrl
      ? req.clone({
        headers: req.headers.delete('fullurl')
      })
      : req.clone({
      url: `${environment.apiHost}${req.url}`
    });

    return next.handle(newRequest).pipe(catchError(err => {
      if (err.status === 401) {
        this.router.navigate(['/account']);
      }

      return throwError(err);
    }));
  }
}
