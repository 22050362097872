<div class="center w800">
  <h1 class="mb1">Front Style Guide</h1>
  <p class="lh-copy">The common styles that we use in Front are shown here on this page. Inspect each element to see the Tachyons styles. Sticking to these elements will great a consistent user experience across our app. However, from time to time it's likely we'll need to break with convention or create new ones.</p>

  <h2 class="mb1">Tachyons</h2>
  <p class="lh-copy">Within Front we are using the Tachyons CSS framework. It is a collection of micro CSS class that can be combined to create pretty much any style you want. The Tachyons library has been imported into Front (/src/app/stylesheets/tachyons-sass/). Any modifications to the Tachyons library live as overrides in the tachyons-extend folder (/src/app/stylesheets/tachyons-extend/).</p>

  <h2 class="mb1">Colors</h2>
  <p class="lh-copy">Tachyons makes our colors available in font colors, background colors and border colors E.g. navy, bg-navy, b--navy.</p>
  <div>
    <div class="bg-orange w4 h4 dib white pa3 mr3 mb3 f6">orange</div>
    <div class="bg-navy w4 h4 dib white pa3 mr3 mb3 f6">navy</div>
    <div class="bg-dark-blue w4 h4 dib white pa3 mr3 mb3 f6">dark-blue</div>
    <div class="bg-lightest-blue w4 h4 dib navy pa3 mr3 mb3 f6">lightest-blue</div>
    <div class="bg-washed-blue w4 h4 dib navy pa3 mr3 mb3 f6">washed-blue</div>
    <div class="bg-washed-green w4 h4 dib white pa3 mr3 mb3 f6">washed-green</div>
    <div class="bg-washed-red w4 h4 dib white pa3 mr3 mb3 f6">washed-red</div>
  </div>

  <h2 class="mb1">Typography</h2>
  <p class="lh-copy">The default font in Front is Avenir. It should be used everywhere.</p>

  <h2 class="mb1">Type scale</h2>
  <p class="lh-copy">Type size is adjusted with the f class.</p>

  <div class="f1 mv2">This is Avenir at f1</div>
  <div class="f2 mv2">This is Avenir at f2</div>
  <div class="f3 mv2">This is Avenir at f3</div>
  <div class="f4 mv2">This is Avenir at f4</div>
  <div class="f5 mv2">This is Avenir at f5</div>
  <div class="f6 mv2">This is Avenir at f6</div>
  <div class="f7 mv2">This is Avenir at f7</div>

  <h2 class="mb1">Tips</h2>
  <p class="lh-copy">Add tips around the app wherever more information would be useful to the user. Tips are labelled as a tip, contain more information and are surrounded by a lightest-blue dashed border.</p>
  <aside class="bg-white br2 ba b--lightest-blue pa2 f6 b--dashed">
    <span class="b">Tip.</span> Invite your boss to see the great work you're doing.
  </aside>

  <h2 class="mb1">Forms</h2>
  <p class="lh-copy">Forms are used throughout Front to accept user input. Settings forms should have an informative header so users are aware of what it does.</p>

  <section class="mb3">
    <header class="bg-lightest-blue pa3 br3 br--top f6">
      <span class="b">Succinct action</span> with more information in a sentence.
    </header>

    <form class="bg-washed-blue br3 br--bottom pa3">
      <p class="ma0 mb3 pa1 br1 f6 lh-copy bg-washed-red ba b--red">Warning: Something bad will happen. <a href="http://www.example.com" target="_blank">Learn more.</a></p>

      <div class="mb3">
        <label class="db b mb1 f6">A field</label>
        <input class="w5 pa2 ba b--lightest-blue br2" />
      </div>
      <div class="mb3">
        <label class="db b mb1 f6">Another field</label>
        <input class="w5 pa2 ba b--lightest-blue br2" />
        <span class="db f7 mt1 navy">Some help text relating to the field</span>
      </div>
      <div class="mb3">
        <label class="db b mb1 f6">A checkbox&hellip;</label>
        <label class="pv2 br2 f6">
          <input type="checkbox" />
          Checkbox label
        </label>
      </div>
      <div class="mb3">
        <input type="submit" class="input-reset button-reset bw0 bg-blue white br2 pv2 ph3 pointer f6" value="Save changes" />
      </div>

      <aside class="bg-white br2 ba b--lightest-blue pa2 f6 b--dashed">
        <span class="b">Tip.</span> Invite your boss to see the great work you're doing.
      </aside>
    </form>
  </section>

  <h2 class="mb1">Buttons</h2>
  <p class="lh-copy">Our button styles can be applied to links or to buttons.</p>
  <h3>Colours</h3>
  <div>
    <a href="#" class="bw0 bg-orange white br2 pv2 ph3 pointer no-underline f6">Orange</a>
    <a href="#" class="bw0 bg-blue white br2 pv2 ph3 pointer no-underline f6">Blue</a>
    <a href="#" class="bw0 bg-lightest-blue dark-blue br2 pv2 ph3 pointer no-underline f6">Lightest-blue</a>
  </div>
  <h3>Sizes</h3>
  <div>
    <a href="#" class="bw0 bg-orange white br2 pv2 ph5 pointer no-underline f6">Wide</a>
    <a href="#" class="bw0 bg-blue white br2 pv2 ph3 pointer no-underline f6">Normal</a>
  </div>


  <h2 class="mb1"></h2>
  <p class="lh-copy"></p>

</div>
