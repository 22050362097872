import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './components/components.module';
import { PipeModule } from './pipe/pipe.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ComponentsModule,
    PipeModule,
    // BrowserAnimationsModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue : '/' }
  ]
})
export class SharedModule { }
