import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  HttpService,
  UserService,
  EventTrackingService,
} from '../shared/service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public eventTrackingService: EventTrackingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userService.user) {
      return this.userService.getUser().pipe(
        map(
          (user) => {
            this.userService.setUser(user);
            this.eventTrackingService.identify(this.userService.user);

            const legacyUx =
              user?.Company?.features?.some(
                (feature) => feature.name === 'legacy-ux'
              ) ?? false;

            if (!user) {
              this.router.navigate(['/account']);
              return false;
            } else if (!legacyUx) {
              window.location.href = 'https://app.nickelled.com';
              return false;
            } else {
              return true;
            }
          },
          (err) => {}
        ),
        catchError((err, caught) => {
          this.router.navigate(['/account']);
          return of(false);
        })
      );
    } else {
      const legacyUx =
        this.userService.user?.Company?.features?.some(
          (feature) => feature.name === 'legacy-ux'
        ) ?? false;

      if (!legacyUx) {
        window.location.href = 'https://app.nickelled.com';
        return false;
      } else {
        return true;
      }
    }
  }
}
