import { Component, OnInit } from '@angular/core';
import { FlashService } from '../../service';

@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss']
})
export class FlashComponent {
  public flashMessage: string;

  constructor(
    private flashService: FlashService
  ) {
    flashService.flashMessage$.subscribe(
      ( message ) => {
        this.flashMessage = message;
        setTimeout(() => {
          this.clearFlash();
        }, 3000);
      }
    );
  }

  clearFlash() {
    this.flashMessage = '';
  }

}
