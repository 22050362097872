<div class="bg-washed-blue h-100 cf" style="height: 100vh">
  <a class="absolute top-0 right-0 pr2 pt1 orange no-underline pointer lh-solid f2" routerLink="/guides">&times;</a>
  <div class="tc">
    <img src="assets/images/nickelled-logo.svg" alt="Nickelled logo" title="Thanks for using Nickelled :)" class="w4 mv4" />
  </div>

  <div class="center w800 tc">
    <div class="tc navy pt4 pb4 bg-white ba b--lightest-blue mb2">
      <h1>Make your first guide</h1>
      <p>Enter the URL for the website you'd like to make a guide for.</p>

      <ul *ngIf="errors.length > 0" class="list ph0">
        <li *ngFor="let error of errors" class="red">{{error}}</li>
      </ul>

      <form #wizardForm="ngForm">
        <div class="mb3">
          <input class="w-60 pa3 ba b--lightest-blue br2 f4" [(ngModel)]="makeUrl" name="makeUrl" placeholder="https://www.yourwebsite.com" autofocus type="text" />
        </div>
        <div>
          <button type="button" class="input-reset button-reset bw0 bg-orange white br2 pv3 ph5 pointer no-underline f5"  (click)="submitForm($event)">Make my guide</button>
        </div>
      </form>
    </div>
    <a routerLink="/guides" class="f6 underline orange pointer">I'll do this later</a>
  </div>
</div>
