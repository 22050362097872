import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';

import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { FlashComponent } from './flash/flash.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GuideSelectComponent } from './guide-select/guide-select.component';
import { PaymentPromptComponent } from './payment-prompt/payment-prompt.component';
import { ArticlesSelectComponent } from './articles-select/articles-select.component';
import { CustomPaginationControlsComponent } from './custom-pagination-controls/custom-pagination-controls.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { LauncherAttrOptionsComponent } from './launcher-attr-options/launcher-attr-options.component';
import { CardComponent } from './card/card.component';
import { CardAttributeComponent } from './card/card-attribute/card-attribute.component';
import { RouterModule } from '@angular/router';
import { ToggleControlComponent } from './toggle-control/toggle-control.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StartButtonComponent } from './start-button/start-button.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ToggleComponent } from './ui/toggle/toggle.component';
import { ButtonComponent } from './ui/button/button.component';
import { InputComponent } from './ui/input/input.component';
import { TextareaComponent } from './ui/textarea/textarea.component';
import { CollapseComponent } from './ui/collapse/collapse.component';
import { VerticalNavLinkComponent } from './vertical-nav-link/vertical-nav-link.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    FlashComponent,
    ConfirmationDialogComponent,
    GuideSelectComponent,
    PaymentPromptComponent,
    ArticlesSelectComponent,
    CustomPaginationControlsComponent,
    DatePickerComponent,
    LauncherAttrOptionsComponent,
    CardComponent,
    CardAttributeComponent,
    ToggleControlComponent,
    StartButtonComponent,
    ModalComponent,
    DropdownComponent,
    ToggleComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    CollapseComponent,
    VerticalNavLinkComponent,
    NavBarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule,
    FontAwesomeModule,
  ],
  exports: [
    LoadingIndicatorComponent,
    FlashComponent,
    ConfirmationDialogComponent,
    GuideSelectComponent,
    PaymentPromptComponent,
    ArticlesSelectComponent,
    CustomPaginationControlsComponent,
    NgxPaginationModule,
    DatePickerComponent,
    LauncherAttrOptionsComponent,
    CardComponent,
    CardAttributeComponent,
    ToggleControlComponent,
    StartButtonComponent,
    ModalComponent,
    DropdownComponent,
    ToggleComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    CollapseComponent,
    VerticalNavLinkComponent,
    NavBarComponent,
  ],
})
export class ComponentsModule {}
