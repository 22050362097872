import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { EventTrackingService } from './shared/service';
import { FeatureService } from './shared/service/feature.service';
import { OnboardingService } from './shared/service/onboarding.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dashboard';
  currentUrl = '';
  currentSection = 'academies';
  activeHeader = false;
  legacyStyles = true;
  onboardingEnabled = false;
  onboardingStatus = '';
  legacyUx = this.feature.has('legacy-ux');
  legacyUxWithFlows = this.feature.has('legacy-ux-with-flows');

  constructor(
    private router: Router,
    private eventTrackingService: EventTrackingService,
    private onboardingService: OnboardingService,
    public feature: FeatureService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.currentSection = this.currentUrl.split('/')[1] ? this.currentUrl.split('/')[1] : 'academies';
        this.activeHeader = this.shouldShowHeader(this.currentUrl);
        this.legacyStyles = this.shouldUseLegacyStyles(this.currentUrl);
      }
    });

    this.onboardingService.onboardingEnabled.subscribe((enable) => this.onboardingEnabled = enable);
    this.onboardingService.stepCompletionStatus.subscribe((stepCompletionStatus) => {
      const totalSteps = this.onboardingService.steps.length;
      const completeSteps = stepCompletionStatus.filter(x => x).length;
      this.onboardingStatus = `${completeSteps}/${totalSteps}`;
    });
  }
  
  ngOnInit(): void {
    this.eventTrackingService.init();
  }

  shouldShowHeader(url: string) {
    return !url.includes('account') && !url.includes('invitation') && !url.includes('wizard');
  }

  shouldUseLegacyStyles(url) {
    return !url.startsWith('/flows') && !url.startsWith('/editor');
  }

}
