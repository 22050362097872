import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NickelledChartService {
public chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    ticks: {
      beginAtZero: true
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label(tooltipItem) {
          return tooltipItem.yLabel + '%';
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          min: 0,
          userCallback(label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          }
        }
      }]
    },
  };

  public chartColors = [
    { // nickelled orange
      backgroundColor: 'rgba(255,82,0,0.4)',
      borderColor: '#FF5200',
      pointBackgroundColor: '#FF5200',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
}
