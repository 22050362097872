import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';

import { AppStorage } from './storage';
@Injectable({
  providedIn: 'root'
})
export class LauncherService {

  constructor(
    private httpService: HttpService,
    private userService: UserService
  ) { }

  get( siteId, launcherType, launcherId ) {
    return this.httpService.get(`/site/${siteId}/${launcherType}/${launcherId}`, { withCredentials: true })
  }

  getPageAttribute(siteId: string, force = false) {
    return AppStorage.cache(
      `get-site-attribute-${siteId}-${this.userService.user.CompanyId}`,
      this.httpService.get(`/sites/${siteId}/attributes`, { withCredentials: true }),
      force
    );
  }

  getSites(force = false) {
    return AppStorage.cache(
      `get-sites`,
      this.httpService.get(`/sites`, { withCredentials: true}),
      force
    );
  }


}
