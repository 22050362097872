import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './user/auth.guard';
import { TrialOrCustomerGuardService } from './user/trial-or-customer-guard.service';
import { InvitationComponent } from './user/invitation/invitation.component';
import { WizardComponent } from './wizard/wizard.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { StartComponent } from './start/start.component';

const routes: Routes = [
  { path: 'start', component: StartComponent, canActivate: [AuthGuard] },
  { path: 'invitation', component: InvitationComponent },
  {
    path: 'academies',
    loadChildren: () => import('./academies/academies.module').then(m => m.AcademiesModule),
    canActivate: [AuthGuard, TrialOrCustomerGuardService]
  },
  { path: 'settings', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule), canActivate: [AuthGuard] },
  { path: 'guides', loadChildren: () => import('./guide-list/guide-list.module').then(m => m.GuideListModule), canActivate: [AuthGuard, TrialOrCustomerGuardService] },
  { path: 'launchers', loadChildren: () => import('./launchers/launchers.module').then(m => m.LaunchersModule), canActivate: [AuthGuard, TrialOrCustomerGuardService] },
  // { path: 'flows', loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule), canActivate: [AuthGuard, TrialOrCustomerGuardService] },
  { path: 'account', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'wizard', component: WizardComponent },
  { path: 'login', redirectTo: 'account/login' },
  { path: 'signin', redirectTo: 'account/login' },
  { path: 'signup', redirectTo: 'account/signup' },
  { path: 'subscribe', redirectTo: 'settings/subscribe' },
  { path: 'subscription', redirectTo: 'settings/subscription' },
  { path: 'cancellation', redirectTo: 'settings/cancellation' },
  { path: 'style-guide', component: StyleGuideComponent },
  { path: '**', redirectTo: 'start' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
