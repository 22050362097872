import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserService } from '../shared/service';

@Injectable({
  providedIn: 'root'
})
export class TrialOrCustomerGuardService implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isChurned;
    let isTrial;

    return this.userService.getUser().pipe(map(
      user => {
        if (user) {
          isChurned = !!user.Company.churnedDate;
          isTrial = user.Company.trial;
        }
        if (user && (isTrial && this.trialIsOver()) || isChurned) {
          this.router.navigate(['/subscribe']);
          return false;
        } else {
          return true;
        }
      }
    ), catchError(
      (err, caught) => {
        return of(false);
      }
    ));
  }

  trialIsOver() {
    const trialEndDate = moment(this.userService.user.Company.trialEndDate);
    const now = moment();

    return now.isAfter(trialEndDate);
  }
}
